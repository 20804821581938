/* Основные стили для статей */
.article-container {
  margin: 0 auto;
  
  background-color: #ffffff;
  border-radius: 10px;
 
  max-width: 800px;
}

/* Стили для заголовков */
.article-title {
  font-size: 2.5em;
  font-weight: 700;
  margin-bottom: 20px;
  color: #2c3e50;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.article-subtitle {
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 15px;
  color: #34495e;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

/* Стили для текста */
.article-text {
  font-size: 1.2em;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #7f8c8d;
  font-family: 'Roboto', sans-serif;
}

/* Стили для изображений */
.article-image {
  max-width: 100%;
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Стили для комментариев */
.article-comments {
  margin-top: 30px;
}

.article-comment {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 10px;
  background-color: #f0f4f8;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.comment-image {
  border-radius: 50%;
  margin-right: 15px;
}

.comment-content {
  max-width: 80%;
  font-family: 'Roboto', sans-serif;
}

.comment-date {
  font-size: 0.9em;
  color: #b0bec5;
  margin-left: 10px;
}

/* Стили для списков */
.article-list {
  margin: 20px 0;
  padding-left: 40px;
  color: #2c3e50;
  font-family: 'Roboto', sans-serif;
}

.article-list li {
  margin-bottom: 10px;
}

/* Стили для цитат */
.article-quote {
  font-size: 1.5em;
  font-style: italic;
  margin: 20px 0;
  padding: 20px;
  background-color: #e8f5e9;
  border-left: 5px solid #388e3c;
  color: #2e7d32;
  font-family: 'Roboto', sans-serif;
}

/* Стили для ссылок */
.article-link {
  color: #3498db;
  text-decoration: none;
  font-weight: bold;
}

.article-link:hover {
  text-decoration: underline;
  color: #1abc9c;
}

/* Адаптивные стили для мобильных устройств */
@media (max-width: 600px) {
  .article-container {
    padding: 15px;
  }

  .article-title {
    font-size: 2em;
  }

  .article-subtitle {
    font-size: 1.3em;
  }

  .article-text {
    font-size: 1em;
  }

  .article-comment {
    flex-direction: column;
    align-items: flex-start;
  }

  .comment-image {
    margin-bottom: 10px;
  }

  .comment-content {
    max-width: 100%;
  }
}
