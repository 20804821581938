/* src/components/CustomNotification.css */
.custom-notification {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    cursor: pointer;
    z-index: 1000;
  }
  
  .notification-header {
    display: flex;
    justify-content: flex-end;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .notification-content h4 {
    margin: 0;
    font-size: 16px;
  }
  
  .notification-content p {
    margin: 5px 0 0;
    font-size: 14px;
  }
  