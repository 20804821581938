/* src/styles/OrderForm.css */

.order-form {
    margin: 20px auto;
    padding: 20px;
    max-width: 450px;
    background-color: #f2f9ff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .form-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.8em;
    color: #00695c;
    font-weight: 600;
  }
  
  .product-details {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #e0f7fa;
    border-radius: 8px;
  }
  
  .product-image {
    width: 150px;
    height: 150px;
    border-radius: 8px;
    margin-right: 20px;
    border: 2px solid #b2ebf2;
  }
  
  .product-info {
    flex: 1;
  }
  
  .product-price,
  .product-discount {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .price {
    color: #d32f2f;
    font-weight: bold;
  }
  
  .discount {
    color: #388e3c;
    font-weight: bold;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    color: #004d40;
  }
  
  .form-group input {
    width: 95%;
    padding: 12px;
    border: 1px solid #b2dfdb;
    border-radius: 4px;
    font-size: 1em;
    color: #004d40;
    background-color: #e0f2f1;
  }
  
  .form-group input:focus {
    outline: none;
    border-color: #4db6ac;
    box-shadow: 0 0 5px rgba(77, 182, 172, 0.5);
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    background-color: #00796b;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #004d40;
  }
  