/* src/styles/ThankYouPopup.css */
.thank-you-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .thank-you-popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
  }
  
  .checkmark-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
  }
  
  .close-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #e53935;
    border: none;
    border-radius: 4px;
    color: rgb(255, 0, 0);
    font-size: 1em;
    cursor: pointer;
  }
  
  .close-button:hover {
    background-color: #d32f2f;
  }
  